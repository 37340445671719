#customers-list-wrapper {
  display: none;
}

#see-bellow-p{
  display: none;
}


.customers-tooltip-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 70vw;
  gap: 30px;
}

.customers-tooltip-logo {
  max-width: 130px;
  aspect-ratio: 16 / 9;
}

.customers-tooltip-country {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: clamp(1em, 0.5em + 2.5vw, 2em);
}

@media screen and (max-width: 980px) {

  #mouse-over-p{
    display: none;
  }

  #see-bellow-p{
    display: block;
  } 

  .customers-list-country {
    font-weight: bold;
    font-size: clamp(0.8em, 0.1em + 5vw, 1.2em);
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  #customers-list-wrapper {
    display: block;
    text-align: center;
  }

  #customers-map-wrapper {
    display: none;
  }

  .customers-list-logo-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .customers-list-logo {
    width: 130px;
    align-self: center;
  }

}