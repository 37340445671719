.contact-section-wrapper {
  display: flex;
}

.contact-section {
  flex-grow: 1;
  margin-right: 40px;
}

.contact-tittle {
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--opt-gray-medium);
}

.agent-location {
margin: 0 20px;
}

.contact-agent {
  display: flex;
  padding-left: 30px;
  margin-bottom: 10px;
}

.contact-item {
  padding-left: 30px;
  margin-bottom: 10px;
}

.contact-icons {
  width: 2vw;
  margin-right: 10px;
  filter: invert(46%) sepia(0%) saturate(388%) hue-rotate(304deg)
    brightness(94%) contrast(95%);
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 980px) {
  .contact-icons {
    width: 5vw;
  }

  .contact-section {
    margin-right: 0px;
  }

  .contact-section {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 480px) {
  .contact-icons {
    width: 8vw;
  }
}
