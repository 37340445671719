#header-wrapper {
  /* opacity: 0.9; */
  background: rgb(86, 101, 117);
  background: linear-gradient(
    270deg,
    rgba(86, 101, 117, 0.6559873949579832) 0%,
    rgba(44, 59, 73, 1) 77%
  );

  color: var(--opt-white);
  position: fixed;
  top: 0;
  width: 100%;
  /* border-bottom: solid 1px var(--opt-white); */
  z-index: 10;
}

#header-container {
  font-size: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 5%;
}

#logo-optcotton-header {
  width: 5vw;
}

.social-icons {
  width: 1.5vw;
  margin-right: 10px;
  filter: invert(100%);
}

#header-buttons-wrapper {
  align-self: center;
  display: block;
}

#header-buttons-mobile-wrapper {
  display: none;
}

#menu-wrapper {
  display: none;
}

.header-buttons {
  margin-left: 15px;
  font-size: 14px;
  color: var(--opt-white);
  background-color: transparent;
  border: none;
}

.header-buttons:hover {
  cursor: pointer;
}

.menu-toggle {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.menu-toggle:focus {
  outline: none;
}

.menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: none;
}

.menu li {
  padding: 10px;
}

.menu li:hover {
  background-color: #f2f2f2;
}

.menu a {
  text-decoration: none;
  color: #333;
}

.language-selector-desktop {
  background-color: transparent;
  line-height: 2em;
  padding: 5px;
  margin-left: 40px;
  color: var(--opt-white);
  border: none;
}
.language-selector-mobile {
  display: none;
}

.language-option {
  color: var(--opt-gray-dark);
}

@media screen and (max-width: 980px) {
  #header-container {
    padding: 10px 8%;
    flex-direction: row-reverse;
  }

  .social-icons {
    width: 3vw;
    margin-left: 10px;
    margin-right: 0px;
  }

  .header-buttons {
    margin-left: 8px;
    font-size: 12px;
  }

  #menu-wrapper {
    display: block;
  }

  #header-buttons-wrapper {
    display: none;
  }
  #header-buttons-mobile-wrapper {
    margin: 0px 8%;
    display: block;
    border-top: 1px solid var(--opt-gray-light);
    padding: 10px 0;
    text-align: center;
  }

  .language-selector-mobile {
    display: block;
    background-color: transparent;
    padding: 5px;
    color: var(--opt-white);
    border: none;
  }

  .language-selector-desktop {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  #header-container {
    padding: 10px 4%;
  }
  #header-buttons-mobile-wrapper {
    margin: 0px 4%;
  }

  .social-icons {
    width: 7vw;
    margin-left: 10px;
    margin-right: 0px;
    opacity: 1;
    transition: opacity 1s ease 0s;
  }
  .social-icons:hover {
    opacity: 0.6;
  }
}
