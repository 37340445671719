.cookie-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #f1f1f1;
  color: #333;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.cookie-popup a {
  color: #555;
  text-decoration: underline;
}

.cookie-popup button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 8px 16px;
  margin-left: 10px;
  border-radius: 3px;
}

.cookie-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookie-content p {
  margin-right: 10px;
}

.cookie-content button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}
