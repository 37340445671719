.footer {
  display: flex;
  justify-content: space-between;
  padding: 50px 10%;
  color: var(--opt-white);
  background: rgb(86, 101, 117);
  background: radial-gradient(
    circle,
    rgba(86, 101, 117, 1) 19%,
    rgba(59, 58, 58, 1) 89%
  );
}

.footer-itens {
  width: 42%;
}

#partner-logos-wrapper {
  display: flex;
}

#ww-logo {
  width: 150px;
  margin-right: 50px;
}

#rieter-logo {
  margin-top: 20px;
  width: 120px;
}

#legal-info {
  text-align: right;
  font-size: 12px;
  color: var(--opt-gray-medium);
}

#menu-footer-wrapper {
  flex-wrap: wrap;
  display: flex;
  justify-content: right;
}

.dev-text {
  font-size: 12px;
  color: var(--opt-gray-medium);
  margin-bottom: 10px;
}

@media screen and (max-width: 980px) {
  .footer {
    display: block;
    padding: 30px 8%;
  }

  #partner-logos-wrapper {
    display: flex;
    justify-content: center;
  }

  .footer-itens {
    width: 100%;
  }

  #ww-logo {
    width: 18vw;
    margin-right: 60px;
  }

  #rieter-logo {
    width: 12vw;
  }

  #legal-info {
    margin-top: 20px;
    text-align: center;
    font-size: 10px;
  }

  .dev-text {
    font-size: 10px;
    color: var(--opt-gray-medium);
    margin-bottom: 10px;
  }

  #menu-footer-wrapper {
    justify-content: center;
  }
}

@media screen and (max-width: 481px) {
  #ww-logo {
    width: 30vw;
    margin-right: 20px;
  }

  #rieter-logo {
    width: 25vw;
  }

  .dev-text {
    font-size: 10px;
    color: var(--opt-gray-medium);
    margin-bottom: 10px;
  }
}
