#customers-wrapper {
  /* display: flex;
  justify-content: space-between;
  gap: 20px; */
  margin-bottom: 20px;
}

.customers-item {
  padding: 20px 0;
  text-align: center;
}

.customers-item-logo {
    align-self: center;
  width: 200px;
  margin-bottom: 15px;
}
