.opt-screenshot-wrapper {
  padding: 20px 0px;
  text-align: center;
}

.opt-screenshot {
  width: 65vw;
  -webkit-box-shadow: 0px 0px 19px 4px rgba(86, 101, 117, 1);
  -moz-box-shadow: 0px 0px 19px 4px rgba(86, 101, 117, 1);
  box-shadow: 0px 0px 19px 4px rgba(86, 101, 117, 1);
}

@media screen and (max-width: 980px) {
  .opt-screenshot {
    width: 100%;
  }
}
