#origin-text-wrapper {
  align-self: center;
  width: 50%;
  margin-right: 30px;
}

#ww-headquarter-img {
  margin-top: 40px;
  margin-bottom: 15px;
  width: 35vw;
  -webkit-box-shadow: 0px 0px 19px 4px rgba(86, 101, 117, 1);
  -moz-box-shadow: 0px 0px 19px 4px rgba(86, 101, 117, 1);
  box-shadow: 0px 0px 19px 4px rgba(86, 101, 117, 1);
}

#ww-headquarter-text {
  color: var(--opt-gray-dark);
  font-size: 12px;
}

@media screen and (max-width: 980px) {
    #origin-text-wrapper {
        width: 100%;
        margin-right: 0px;
      }
      
      #ww-headquarter-img {
        margin-top: 20px;
        margin-bottom: 15px;
        width: 85vw;
      }
      
  }
  