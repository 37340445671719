:root {
  --swiper-navigation-size: 44px;
  --swiper-theme-color: var(--opt-primary-dark);
}

.benefits-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
}

.benefits-item {
  padding: 70px 10%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5999649859943977) 0%,
    rgba(86, 101, 117, 1) 100%
  );
  color: var(--opt-white);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.benefits-title {
  font-size: clamp(1.5em, 0.1em + 2vw, 4em);
}

.benefits-text {
  font-size: clamp(0.8em, 0.1em + 0.8vw, 2em);
  max-width: 600px;
}

.swiper-pagination {
  margin-bottom: 20px;
  position: relative;
}

/* .swiper-pagination-bullet_active {
  background-color: var(--opt-white);
} */

