#benefits-wrapper {
  background-image: url("/src/images/opt-production.jpg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

#benefits-filter {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 49%,
    rgba(255, 255, 255, 0) 100%
  );
}

#benefits-text-wrapper {
  width: 70%;
}

@media screen and (max-width: 980px) {
  #benefits-wrapper {
    background-image: none;
  }

  #benefits-text-wrapper {
    width: 100%;
  }
}
