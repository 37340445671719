.form-item {
  margin-bottom: 20px;
}

.form-label-wrapper {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
}

.form-input-wrapper {
  width: 100%;
  font-size: 16px;
}

.form-input-wrapper-location {
  width: 50%;
}

.form-input {
  width: 50%;
  /* padding: 3px 8px; */
}

@media screen and (max-width: 980px) {
  .form-input {
    width: 75%;
  }
}

@media screen and (max-width: 480px) {
  .form-input {
    width: 95%;
  }
}
