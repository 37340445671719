@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
  --opt-primary: #566575;
  --opt-primary-dark: #2c3b49;
  --opt-white: #ffffff;
  --opt-secondary: #8393a4;
  --opt-gray-dark: #6f7072;
  --opt-gray-medium: #b4bec3;
  --opt-gray-light: #fdfdfe;
  --opt-red: #be0019;
  --opt-orange: #f09100;
  --opt-turqouise: #0091aa;
  --opt-green: #afc800;
  --main-font-color: #222;
}

html,
body {
  margin: 0;
  min-height: 100vh;
  min-width: 100%;
  font-family: "Noto Sans", sans-serif !important;
  font-size: clamp(1.1em, 0.1em + 2.5vw, 1.2em);
  color: var(--opt-primary-dark);
}

#root {
  min-height: 100vh;
}

.wrapper {
  min-height: 100vh;
}

#page-wrapper {
  min-height: calc(100vh - 320px);
  padding: 70px 10% 40px 10%;
  font-size: clamp(0.6em, 0.1em + 4vw, 0.7em);
  color: var(--opt-gray-dark);
}

.page-title {
  font-size: clamp(0.8em, 0.1em + 8vw, 1.9em);
  font-weight: bold;
  color: var(--opt-primary-dark);
}

.flex {
  display: flex;
}

.bold {
  font-weight: bold;
}

.topics {
  font-weight: bold;
  font-size: clamp(0.8em, 0.1em + 5vw, 1.2em);
}

.links:active {
  color: var(--opt-primary-dark);
}

.links:hover,
:visited {
  color: var(--opt-primary-dark);
}

#home-main-bg {
  background-image: url("/src/images/opr-quality.jpg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 45vw;
  min-height: 100vh;
}

#home-main-bg-filter {
  color: var(--opt-white);
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 0 10% 60px 10%;
}

.blue-filter {
  background: rgb(86, 101, 117);
  background: linear-gradient(
    270deg,
    rgba(86, 101, 117, 0.6559873949579832) 0%,
    rgba(44, 59, 73, 1) 77%
  );
}

#home-main-btn-wrapper {
  display: flex;
  column-gap: 20px;
}

#logo-optcotton-home {
  width: 12vw;
  margin-bottom: 20px;
}

#app-title {
  width: 50%;
  font-size: clamp(1.6em, 0.5em + 2.5vw, 4em);
  margin-bottom: 20px;
}

#app-description {
  color: var(--opt-gray-medium);
  font-size: clamp(0.7em, 0.1em + 0.8vw, 1em);
  width: 40%;
  margin-bottom: 50px;
}

#itma-invitation-wrapper {
  background-image: url("./images/fiera-milano.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  overflow: hidden;
  text-align: center;
}

#itma-invitation-filter {
  padding: 70px 10%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.59156162464986) 100%,
    rgba(0, 0, 0, 0.036939775910364125) 100%
  );
  color: var(--opt-white);
  min-height: 100%;
}

#how-it-works-wrapper {
  display: flex;
  padding: 50px 10%;
}

#how-it-works-text-wrapper {
  width: 60%;
}

#how-it-works-text {
  margin-right: 20px;
}

.how-it-works-img {
  width: 35vw;
}

#mixing-control-wrapper {
  padding: 50px 10%;
  text-align: center;
}

#mixing-control-topics-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.mixing-control-topics-item {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.mixing-control-topics-bg {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--opt-primary-dark);
  width: 160px;
  height: 160px;
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  margin-bottom: 10px;
}

#mixing-control-topics-item1 {
  background-image: url("./images/opt-quality-planning.jpg");
  background-position: left;
}

#mixing-control-topics-item2 {
  background-image: url("./images/opt-bale-taking.jpg");
}

#mixing-control-topics-item3 {
  background-image: url("./images/opt-bale-selection.jpg");
  background-position: right;
}
#mixing-control-topics-item4 {
  background-image: url("./images/opt-laydown.jpg");
  background-position: right;
}

#mixing-control-text {
  margin-bottom: 30px;
}

.mixing-control-topics-img {
  width: 155px;
  height: 155px;
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  background-repeat: no-repeat;
  background-size: cover;
}

.mixing-control-topics-text {
  text-align: center;
  color: var(--opt-gray-medium);
  font-size: clamp(0.7em, 0.2em + 0.8vw, 1em);
  margin-bottom: 50px;
}

.main-button-blue {
  min-width: 100px;
  padding: 12px 25px;
  background-color: var(--opt-primary);
  border-radius: 30px;
  border: none;
  color: var(--opt-white);
  cursor: pointer;
  opacity: 1;
  transition: opacity 1s ease 0s;
}

.main-button-blue:hover {
  opacity: 0.5;
}

.main-button-white {
  min-width: 100px;
  padding: 12px 25px;
  background-color: var(--opt-white);
  border-radius: 30px;
  border: none;
  color: var(--opt-primary);
  cursor: pointer;
  opacity: 1;
  transition: opacity 1s ease 0s;
}

.main-button-white:hover {
  opacity: 0.5;
}

.blue-text-home {
  color: var(--opt-primary-dark-dark);
  font-size: clamp(1em, 1em + 1vw, 3em);
}

#main-benefits-wrapper {
  background-image: url("./images/opt-cotton-bales.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#main-benefits-filter {
  padding: 70px 10%;
  color: var(--opt-white);
}

#main-benefits-text {
  margin-bottom: 30px;
}

.right-icon {
  filter: invert(100%);
  width: 20px;
  margin-right: 10px;
}

.benefits-item {
  margin-bottom: 10px;
  font-style: italic;
}

#ai-text-wrapper {
  display: flex;
  padding: 20px 10%;
  justify-content: space-between;
  background: rgb(245, 245, 246);
  background: linear-gradient(
    180deg,
    rgba(245, 245, 246, 1) 2%,
    rgba(255, 255, 255, 0.036939775910364125) 100%
  );
}

#ai-text {
  flex-grow: 1;
  align-self: center;
}

#img-mobile-person {
  width: 30vw;
}

#opt-video-wrapper {
  width: 35vw;
  height: 35vh;
  min-width: 250px;
  -webkit-box-shadow: 0px 0px 32px 3px rgba(86, 101, 117, 1);
  -moz-box-shadow: 0px 0px 32px 3px rgba(86, 101, 117, 1);
  box-shadow: 0px 0px 32px 3px rgba(86, 101, 117, 1);
}

#customers-text-wrapper {
  text-align: center;
  padding: 50px 10%;
}

@media screen and (max-width: 980px) {
  .flex {
    display: block;
  }

  #page-wrapper {
    padding: 70px 8% 40px 8%;
  }

  #home-main-bg {
    min-height: auto;
  }

  #home-main-bg-filter {
    min-height: 100%;
    padding: 100px 8% 60px 8%;
  }

  #logo-optcotton-home {
    width: 20vw;
    margin-bottom: 20px;
  }

  .main-button-white,
  .main-button-blue {
    padding: 10px 20px;
  }

  #how-it-works-wrapper {
    display: block;
    padding: 30px 8%;
    text-align: center;
  }

  #how-it-works-text-wrapper {
    width: 100%;
  }

  #how-it-works-text {
    margin-right: 0px;
  }

  .how-it-works-img {
    width: 60vw;
    margin-top: 20px;
  }

  #main-benefits-wrapper {
    background-position: left;
  }

  #main-benefits-filter {
    padding: 50px 8%;
  }

  #main-benefits-text {
    width: 100%;
  }

  .right-icon {
    width: 15px;
  }

  #mixing-control-wrapper {
    display: block;
    padding: 50px 8%;
    text-align: center;
  }

  #mixing-control-wrapper {
    display: block;
    padding: 50px 8%;
  }

  #mixing-control-text {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    margin-top: 30px;
    /* margin-bottom: 30px; */
  }
  .video-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .separator {
    background-size: 85%;
  }

  #ai-text-wrapper {
    display: block;
    padding: 20px 8%;
    text-align: center;
  }

  #img-mobile-person {
    width: 60vw;
    margin-top: 30px;
  }

  #customers-text-wrapper {
    text-align: center;
    padding: 50px 8%;
  }
}

@media screen and (max-width: 480px) {
  #page-wrapper {
    min-height: calc(100vh - 350px);
    padding: 70px 4% 40px 4%;
  }

  #page-wrapper {
    padding: 70px 4% 40px 4%;
  }

  #home-main-bg {
    background-position: right bottom;
    background-size: 90vw;
    bottom: 20px;
    height: calc(100vh - 50px);
  }

  #home-main-bg-filter {
    align-items: flex-start;
    text-align: center;
    padding: 0 8% 0x 8%;
  }

  .blue-filter {
    background: rgb(86, 101, 117);
    background: linear-gradient(
      0deg,
      rgba(86, 101, 117, 0.6559873949579832) 0%,
      rgba(44, 59, 73, 1) 77%
    );
  }

  #logo-optcotton-home {
    width: 40vw;
    margin-bottom: 20px;
  }

  #app-title {
    width: 100%;
    margin-bottom: 20px;
  }

  #app-description {
    width: 100%;
    margin-bottom: 50px;
  }

  #home-main-btn-wrapper {
    display: flex;
    column-gap: 20px;
    justify-content: center;
  }

  .main-button-white {
    padding: 10px 20px;
  }

  #how-it-works-wrapper {
    padding: 30px 4%;
  }

  .how-it-works-img {
    width: 85vw;
  }

  #main-benefits-wrapper {
    background-position: center;
  }

  #main-benefits-filter {
    padding: 50px 4%;
    text-align: center;
  }

  #opt-video-wrapper {
    width: 35vw;
    height: 35vh;
    /* min-width: 300px; */
  }

  #mixing-control-wrapper {
    padding: 30px 4%;
  }

  #mixing-control-topics-wrapper {
    display: block;
  }

  .mixing-control-topics-text {
    margin-bottom: 20px;
  }

  #ai-text-wrapper {
    padding: 20px 4%;
  }

  #img-mobile-person {
    width: 90vw;
    margin-top: 30px;
  }

  #customers-text-wrapper {
    padding: 30px 4%;
  }

  #customers-list-home {
    display: block;
  }

  .customers-list-home-item {
    width: 100%;
    margin-bottom: 30px;
  }

  .customers-list-item-logo {
    width: 40vw;
    margin-bottom: 5px;
  }
}
